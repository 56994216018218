<template>
  <div style="padding: 0px 20px;">
    <div style="height:50px;margin-top:30px;margin-bottom:30px;">
      <div class="flex-center head-left">
        <el-button @click="newTags = true">新建标签</el-button>
        <span class="prompt">
          标签数量上限999个，当前可创建{{ 999 - total }}个
        </span>
      </div>
      <div class="head-right">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              v-model.trim="formInline.search"
              placeholder="输入标签名称搜索"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <pagination2
      ref="jigouTag"
      :option="formInline"
      url="/UserTag/getUserTag"
      @complete=";(total = arguments[0].total), (tableData = arguments[0].data)"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333'
          }"
        >
          <el-table-column prop="tag_name" label="名称"></el-table-column>
          <el-table-column prop="c_time" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <el-table-column prop="uname" label="创建人"></el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="updataTags(scope.row, scope.$index)"
              >
                编辑
              </el-button>
              <span
                style="vertical-align: middle; display: inline-block;padding:0px 10px;padding-bottom:3px; color:#1B9D97"
              >
                |
              </span>
              <el-button
                type="text"
                size="small"
                @click="delesteTag(scope.row, scope.$index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <el-dialog
      title="新建标签"
      :visible.sync="newTags"
      width="422px"
      custom-class="new-tag"
      :close-on-click-modal="false"
    >
      <div style="margin:20px 20px;margin-top:0px;">
        <el-input
          v-model.trim="inputTag"
          maxlength="8"
          show-word-limit
          placeholder="输入标签"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click=";(inputTag = ''), (newTags = false)">
          取 消
        </el-button>
        <el-button type="primary" @click="newTag">完 成</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="编辑标签"
      :visible.sync="updataTag"
      width="422px"
      custom-class="new-tag"
      :close-on-click-modal="false"
    >
      <div style="margin:20px 20px;margin-top:0px;">
        <el-input
          v-model.trim="updataInput"
          maxlength="8"
          show-word-limit
          placeholder="输入标签"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updataTag = false">取消</el-button>
        <el-button type="primary" @click="upTag">完成</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import loading from '@/components/LoadGif'
export default {
  data() {
    return {
      formInline: {
        search: ''
      },
      items: [],
      total: 0, // 数据总数量
      tableData: [],
      newTags: false, //添加标签弹窗
      inputTag: '', //新标签
      updataTag: false, //编辑标签弹窗
      updataInput: '', //编辑标签
      nowUpdataTag: '', //要编辑标签的所有tabdata的内容
      nowUpdataindex: '' //编辑标签的tabdata的下标
    }
  },
  methods: {
    newTag() {
      //新建标签
      const that = this
      if (!that.inputTag) {
        that.newTags = false
      } else {
        that.$http({
          name: 'studentData',
          url: 'UserTag/updateUserTag',
          isMultipleSelf: 'replacePrev',
          data: {
            tag_name: that.inputTag || ''
          },
          callback() {
            that.$refs.jigouTag.reset()
            that.$root.prompt({
              type: 'success',
              msg: '新建成功'
            })
            that.inputTag = ''
            that.newTags = false
          }
        })
      }
    },
    delesteTag(item) {
      //删除
      const that = this
      that
        .$confirm('是否删除该标签？', '删除标签', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        .then(() => {
          that.isLoading = true
          that.$http({
            name: 'DeleteTag',
            url: 'UserTag/delTag',
            data: {
              tag_id: item.tag_id
            },
            callback() {
              that.isLoading = false
              that.$refs.jigouTag.reset()
              that.$root.prompt({
                type: 'success',
                msg: '删除成功'
              })
            }
          })
        })
    },
    updataTags(item, index) {
      //编辑弹窗出现
      const that = this
      that.updataTag = true
      that.nowUpdataTag = item
      that.nowUpdataindex = index
      that.updataInput = that.tableData[that.nowUpdataindex].tag_name
    },
    upTag() {
      //编辑
      const that = this
      if (!that.updataInput) {
        that.updataTag = false
      } else {
        that.$http({
          name: 'UpdataTag',
          url: 'UserTag/updateUserTag',
          data: {
            tag_id: that.nowUpdataTag.tag_id,
            tag_name: that.updataInput || ''
          },
          callback() {
            that.tableData[that.nowUpdataindex].tag_name =
              that.updataInput || ''
            that.$root.prompt({
              type: 'success',
              msg: '修改成功'
            })
            that.updataTag = false
            that.updataInput = ''
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.head-left {
  float: left;
  .prompt {
    font-size: 12px;
    line-height: 16px;
    margin-left: 20px;
    color: rgba(102, 102, 102, 1);
  }
}
.head-right {
  height: 40px;
  float: right;
  margin-top: 10px;
}
.head-left .el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.head-left .el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button--text {
  color: #1b9d97;
}
/deep/ .el-button--primary {
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-button.el-button--default:hover {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
</style>
