<template>
  <div style="padding: 0px 20px">
    <div style="height: 50px; margin-top: 30px; margin-bottom: 30px">
      <div class="head-left">
        <el-button @click="newGounps = true">新建分组</el-button>
      </div>
      <div class="head-right">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              clearable
              v-model.trim="formInline.search"
              placeholder="输入分组名称搜索"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <pagination2 ref="groupLis" :option="formInline" url="/pcUser/getGroupList">
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column prop="gname" label="分组名称"></el-table-column>
          <el-table-column prop="number" label="学员数"></el-table-column>
          <el-table-column prop="create_time" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.create_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column prop="cname" label="创建人"></el-table-column>
          <el-table-column label="操作" width="220px" align="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="statistics(scope.row)"
              >
                数据统计
              </el-button>
              <span
                style="
                  vertical-align: middle;
                  display: inline-block;
                  padding: 0px 10px;
                  padding-bottom: 3px;
                  color: #1b9d97;
                "
              >
                |
              </span>
              <el-button
                type="text"
                size="small"
                @click="updataGroups(scope.row, scope.$index)"
              >
                编辑
              </el-button>
              <span
                style="
                  vertical-align: middle;
                  display: inline-block;
                  padding: 0px 10px;
                  padding-bottom: 3px;
                  color: #1b9d97;
                "
              >
                |
              </span>
              <el-button
                type="text"
                size="small"
                @click="groupAdmin(scope.row)"
              >
                查看
              </el-button>
              <span
                style="
                  vertical-align: middle;
                  display: inline-block;
                  padding: 0px 10px;
                  padding-bottom: 3px;
                  color: #1b9d97;
                "
              >
                |
              </span>
              <el-button
                type="text"
                size="small"
                @click="delesteGroup(scope.row, scope.$index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <el-drawer
      class="drawer-contain"
      :visible="!!post.gid"
      @close="post.gid = ''"
      :modal="false"
    >
      <div class="title">
        <span class="t1">{{ post.gname }}（{{ post.gNumber }}）</span>
      </div>
      <div class="search-contain">
        <el-input
          placeholder="输入昵称/真实姓名搜索"
          v-model="post.search"
          @input="checked = []"
          clearable
        ></el-input>
      </div>
      <div v-if="post.gid" class="content">
        <pagination2
          :option="post"
          url="/PcUser/groupUserList"
          :allFn="true"
          ref="groupUserList"
          @complete="checked = []"
        >
          <template v-slot:default="{ tableData, empty }">
            <template v-if="!empty">
              <div class="flex-center">
                <div class="check-all">
                  <el-checkbox
                    :value="
                      checked.length > 0 && tableData.length == checked.length
                    "
                    :indeterminate="
                      checked.length > 0 &&
                        !(tableData.length == checked.length)
                    "
                    @change="checkAllStudent(arguments[0], tableData)"
                  >
                    选择本页
                  </el-checkbox>
                </div>
                <div @click="move" class="move-out">批量移出</div>
              </div>
              <el-checkbox-group v-model="checked">
                <ul class="student-list">
                  <li v-for="item in tableData" :key="item.uid">
                    <el-checkbox class="flex-center" :label="item.uid">
                      <div class="tu1">
                        <img class="block" :src="item.uphoto" alt="" />
                      </div>
                      <div class="zi1" v-if="item.student_remarks">
                        {{ item.uname }}({{ item.student_remarks }})
                      </div>
                      <div class="zi1" v-else>{{ item.uname }}</div>
                      <div
                        class="zi2"
                        @click.prevent.stop="aloneMove(item.uid)"
                      >
                        移出分组
                      </div>
                    </el-checkbox>
                  </li>
                </ul>
              </el-checkbox-group>
            </template>
            <div class="empty" v-else>
              <img class="img" src="~@ass/img/1.3.1/ico_gth.png" alt="" />
              <div class="prompt">
                该分组未添加学员，可在【学员>>全部】列表中可批量添加
              </div>
            </div>
          </template>
        </pagination2>
      </div>
    </el-drawer>

    <el-dialog
      title="新建分组"
      :visible.sync="newGounps"
      width="422px"
      custom-class="new-tag"
      :close-on-click-modal="false"
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <el-input
          v-model.trim="inputTag"
          maxlength="12"
          show-word-limit
          placeholder="请输入分组名称"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newGounps = false">取消</el-button>
        <el-button type="primary" @click="newGounp">确定</el-button>
      </span>
    </el-dialog>
    <!---------------------------------------------------- 编辑弹窗 --------------------------------------------------------------->
    <el-dialog
      title="编辑分组"
      :visible.sync="updataGroup"
      width="422px"
      custom-class="new-tag"
      :close-on-click-modal="false"
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <el-input
          v-model.trim="updataInput"
          maxlength="12"
          show-word-limit
          placeholder="输入分组"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updataGroup = false">取消</el-button>
        <el-button type="primary" @click="upTag">完成</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      post: {
        gid: '',
        search: '',
        gname: '',
        gNumber: 0,
      },
      formInline: {
        search: '',
        reset: '',
      },
      checked: [],
      items: [],
      tableData: [],
      isLoading: false,
      newGounps: false, //添加分组弹窗
      inputTag: '', //新分组
      nowPage: 1, //当前页数
      isTotal: '', //数据总量
      updataGroup: false, //编辑分组弹窗
      updataInput: '', //编辑分组
      nowupdataGroup: '', //要编辑分组的所有tabdata的内容
      nowUpdataindex: '', //编辑分组的tabdata的下标
    }
  },

  watch: {
    'post.gid'(value) {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },

  methods: {
    statistics(val) {
      this.$router.push({
        path: '/manageStudent/statisticsdata',
        query: { gid: val.gid },
      })
    },

    search() {
      this.nowPage = 1
    },

    newGounp() {
      //新建分组
      const that = this
      if (!that.inputTag) {
        that.newGounps = false
      } else {
        that.$http({
          name: 'studentData',
          url: 'pcUser/addGroup',
          isMultipleSelf: 'replacePrev',
          data: {
            name: that.inputTag || '',
          },
          callback() {
            that.$refs.groupLis.reset()
            that.$root.prompt({
              type: 'success',
              msg: '新建成功',
            })
            that.inputTag = ''
            that.newGounps = false
          },
        })
      }
    },

    delesteGroup(item) {
      //删除
      const that = this
      that
        .$confirm('删除后，分组里的学员会被释放！', '删除分组', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          that.isLoading = true
          that.$http({
            name: 'DeleteTag',
            url: 'pcUser/delGroup',
            data: {
              gid: item.gid,
            },
            callback() {
              that.isLoading = false
              that.$refs.groupLis.reset()
              that.$root.prompt({
                type: 'success',
                msg: '删除成功',
              })
            },
          })
        })
    },

    // 分组管理
    groupAdmin(item) {
      this.post = _.assign({}, this.post, {
        gid: item.gid,
        gname: item.gname,
        gNumber: item.number,
      })
    },

    // 单个移出分组
    aloneMove(id) {
      this.checked = [id]
      this.move()
    },

    //移除分组
    move() {
      if (this.checked.length == 0) return
      const that = this
      that
        .$confirm('是否将该学员移出当前分组？', '移出分组', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          that.$http({
            name: 'DeleteTag',
            url: '/pcUser/removeGroup',
            data: {
              uids: this.checked,
              gid: this.post.gid,
            },
            callback() {
              that.$root.prompt({
                type: 'success',
                msg: '移出分组成功',
              })
              that.post.gNumber -= that.checked.length
              that.$refs.groupUserList.reset()
              that.$refs.groupLis.reset()
            },
          })
        })
    },

    // 选中所有学生
    checkAllStudent(value, list) {
      if (value) {
        this.checked = list.map(item => item.uid)
      } else {
        this.checked = []
      }
    },
    updataGroups(item, index) {
      //编辑弹窗出现
      const that = this
      that.updataGroup = true
      that.nowupdataGroupp = item
      that.nowUpdataindex = index
      that.updataInput = item.gname
    },
    upTag() {
      //编辑
      const that = this
      if (!that.updataInput) {
        that.updataGroup = false
      } else {
        that.$http({
          name: 'updataGroup',
          url: 'pcUser/editGroup',
          data: {
            gid: that.nowupdataGroupp.gid,
            name: that.updataInput || '',
          },
          callback() {
            that.$refs.groupLis.reset()
            that.$root.prompt({
              type: 'success',
              msg: '修改成功',
            })
            that.updataGroup = false
            that.updataInput = ''
          },
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  .student-list {
    /deep/ .el-checkbox__label {
      flex: 1;
      display: flex;
      overflow: hidden;
      align-items: center;
    }
    li {
      width: 93%;
      height: 46px;
      padding-left: 20px;
      padding-top: 12px;
      padding-right: 20px;
      .tu1 {
        width: 34px;
        height: 34px;
        float: left;
        margin-left: 10px;
        border-radius: 17px;
        background: gray;
      }
      .zi1 {
        flex: 1;
        height: 19px;
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        line-height: 19px;
        float: left;
        margin-left: 10px;
        margin-top: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .zi2 {
        height: 19px;
        font-size: 14px;
        color: white;
        line-height: 19px;
        float: right;
        margin-top: 9px;
        cursor: pointer;
      }
    }
    li:hover {
      background: rgba(245, 245, 245, 1);
    }
    li:hover .zi2 {
      color: rgba(255, 53, 53, 1);
    }
  }
}
/deep/ .head-left {
  float: left;
}
/deep/ .head-right {
  height: 40px;
  float: right;
  margin-top: -5px;
  .el-input {
    width: 218px;
    height: 36px;
    input {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.head-left .el-button {
  width: 100px !important;
  height: 36px !important;
  border-radius: 4px !important;
  color: #fff !important;
  padding: 10px 20px;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.head-left .el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button--text {
  color: #1b9d97;
}
/deep/ .el-button--primary {
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-button.el-button--default:hover {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
/deep/ .el-dialog__body {
  padding: 10px 0 0 0;
}
/deep/ .el-drawer {
  min-width: 436px;
  box-shadow: -7px 0px 12px 0px rgba(0, 0, 0, 0.2);
  .title {
    position: absolute;
    top: 18px;
    left: 20px;
    .t1 {
      font-size: 14px;
      line-height: 19px;
      color: rgba(51, 51, 51, 1);
    }
    .t2 {
      font-size: 12px;
      line-height: 22px;
      margin-left: 16px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
/deep/ .drawer-contain {
  .el-drawer {
    background: #f5f5f5;
  }
  .el-drawer__body {
    display: flex;
    flex-direction: column;
    .search-contain {
      padding: 0 20px;
      margin-bottom: 20px;
    }
    .content {
      flex: 1;
      position: relative;
      background: #fff;
      .empty {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .img {
          width: 44px;
          height: 44px;
          margin: 0 auto;
          display: block;
        }
        .prompt {
          font-size: 13px;
          line-height: 17px;
          margin-top: 37px;
          text-align: center;
          white-space: nowrap;
          color: rgba(51, 51, 51, 1);
        }
      }
      .el-checkbox-group {
        position: absolute;
        width: 100%;
        top: 43px;
        left: 0;
        bottom: 112px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .pagination {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}
.dialog-footer {
  position: relative;
  .prompt {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    color: #ff3535;
    top: 50%;
    transform: translateY(-50%);
  }
}
.check-all {
  flex: 1;
  padding: 10px 0 10px 20px;
}
.move-out {
  font-size: 14px;
  line-height: 19px;
  padding: 0 20px;
  cursor: pointer;
  color: rgba(255, 53, 53, 1);
}
</style>

<style>
:focus {
  outline: 0;
}
</style>
