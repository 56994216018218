<template>
  <div class="contain">
    <div class="custombtn">
      <el-button type="text" icon="el-icon-s-operation" @click="forCustom">
        自定义列表
      </el-button>
    </div>

    <!-- {{ showList }} -->
    <el-table
      v-if="ishow"
      :data="tableData"
      :header-cell-style="{
        background: 'rgba(245,245,245,1)',
        color: '#333333',
      }"
      @select-all="selectAll"
      ref="table"
      :max-height="tableMaxHeight"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" fixed />
      <!-- <el-table-column prop="uid" label="用户ID" fixed></el-table-column> -->
      <el-table-column prop="name" label="学员" fixed min-width="150">
        <template slot-scope="scope">
          <div class="flex-center user-info">
            <img class="photo" :src="scope.row.uphoto" />
            <span class="uname">{{ scope.row.uname || '- -' }}</span>
          </div>
        </template>
      </el-table-column>

      <div v-for="item in showList" :key="item.id">
        <el-table-column
          :prop="item.label"
          label="用户ID"
          v-if="item.label == 'uid'"
        >
          <template slot-scope="{ row }">{{ row.uid }}</template>
        </el-table-column>

        <el-table-column
          min-width="120px"
          v-else-if="item.label == 'umobile'"
          :prop="item.label"
          label="手机号"
        >
          <template slot-scope="{ row }">
            {{ row.umobile || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'student_remarks'"
          :prop="item.label"
          label="真实姓名"
        >
          <template slot-scope="{ row }">
            {{ row.student_remarks || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'reg_source'"
          :prop="item.label"
          min-width="150px"
          label="注册来源"
        >
          <template slot-scope="scope">
            {{
              scope.row.reg_source == 1
                ? 'h5网校'
                : scope.row.reg_source == 2
                ? '叮叮开课直播小程序'
                : '推广招生-抖音广告'
            }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'regdate'"
          :prop="item.label"
          sortable="custom"
          min-width="150px"
          label="注册时间"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.regdate > 0">
              {{ scope.row.regdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'usex'"
          :prop="item.label"
          label="性别"
        >
          <template slot-scope="{ row }">
            {{ row.usex || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'age'"
          :prop="item.label"
          label="年龄"
        >
          <template slot-scope="scope">
            {{ scope.row.age || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'area'"
          :prop="item.label"
          label="地区"
        >
          <template slot-scope="scope">
            {{ scope.row.area || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'position'"
          :prop="item.label"
          label="职业"
        >
          <template slot-scope="scope">
            {{ scope.row.position || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          min-width="120px"
          v-else-if="item.label == 'company'"
          :prop="item.label"
          label="机构(单位)"
        >
          <template slot-scope="scope">
            {{ scope.row.company || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'uemail'"
          :prop="item.label"
          label="邮箱"
        >
          <template slot-scope="scope">
            {{ scope.row.uemail || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'wx_number'"
          :prop="item.label"
          label="微信号"
        >
          <template slot-scope="scope">
            {{ scope.row.wx_number || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'gname'"
          :prop="item.label"
          min-width="120px"
          label="分组"
        >
          <template slot-scope="scope">
            {{ scope.row.gname || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'tag'"
          :prop="item.label"
          label="标签"
        >
          <template slot-scope="scope">
            <tagselect
              :uids="scope.row.uid"
              :tag="scope.row.tag"
              :isCustom="true"
            >
              <template v-slot:reference="{ tag }">
                <el-button type="text">
                  {{ !tag ? '设置' : tag.split(',').length + '个标签' }}
                </el-button>
              </template>
            </tagselect>
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'openid'"
          :prop="item.label"
          label="微信openid"
          min-width="120px"
        >
          <template slot-scope="scope">
            {{ scope.row.openid || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'order_quantity'"
          :prop="item.label"
          min-width="150px"
          sortable="custom"
          label="消费次数"
        ></el-table-column>

        <el-table-column
          v-else-if="item.label == 'order_amount'"
          :prop="item.label"
          label="消费金额(元)"
          min-width="150px"
          sortable="custom"
        >
          <template slot-scope="scope">
            {{ scope.row.order_amount || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'last_buy_time'"
          :prop="item.label"
          label="最近消费时间"
          sortable="custom"
          min-width="150px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.last_buy_time > 0">
              {{
                scope.row.last_buy_time | formatTimeStamp('yyyy-MM-dd hh:mm')
              }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'lastdate'"
          :prop="item.label"
          label="最后登录时间"
          sortable="custom"
          min-width="150px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.lastdate > 0">
              {{ scope.row.lastdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'first_buy_time'"
          :prop="item.label"
          label="首次支付时间"
          sortable="custom"
          min-width="150px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.first_buy_time > 0">
              {{
                scope.row.first_buy_time | formatTimeStamp('yyyy-MM-dd hh:mm')
              }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="item.label == 'logincount'"
          :prop="item.label"
          label="登录次数"
          min-width="150px"
          sortable="custom"
        >
          <template slot-scope="scope">
            {{ scope.row.logincount || '-' }}
          </template>
        </el-table-column>
      </div>

      <el-table-column label="操作" min-width="260" fixed="right">
        <template slot-scope="scope">
          <div class="flex-center">
            <el-button
              type="text"
              size="small"
              @click="nav('/manageStudent/detail', scope)"
            >
              详情
            </el-button>
            <span class="space">|</span>
            <el-button
              type="text"
              size="small"
              @click="
                showCategory = {
                  uid: scope.row.uid,
                  gid: scope.row.gid,
                  uname: scope.row.uname,
                }
              "
            >
              分组
            </el-button>
            <span class="space">|</span>
            <el-button
              type="text"
              size="small"
              @click="nav('/manageStudent/detail?index=6', scope)"
            >
              访问记录
            </el-button>
            <span class="space">|</span>
            <!-- <el-button
              type="text"
              size="small"
              @click.native="nav('/manageStudent/watchrecords', scope)"
            >
              观看记录
            </el-button> -->
            <el-button
              type="text"
              size="small"
              @click.native="nav('/manageStudent/detail?index=2', scope)"
            >
              学习记录
            </el-button>
            <span class="space">|</span>
            <el-button
              :disabled="!showBlackBtn"
              type="text"
              size="small"
              @click.native="showBlackConfirm = scope.row.uid"
            >
              拉黑
            </el-button>
            <!-- <el-dropdown>
              <el-button type="text" size="small">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="nav('/manageStudent/watchrecords', scope)"
                >
                  观看记录
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="showBlackBtn"
                  @click.native="showBlackConfirm = scope.row.uid"
                >
                  拉黑
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <category
      v-if="showCategory"
      :groupList="groupList"
      :selectedData="showCategory"
      @close="showCategory = false"
      @reset="$emit('reset')"
    ></category>
    <!--拉黑-->
    <blackConfirm
      title="拉黑"
      v-if="showBlackConfirm"
      @close="showBlackConfirm = false"
      @complete="blacklist"
      placeholder="输入拉黑原因，选填"
      content="拉黑后学员无法进入网校、查看课程、报名课程，进入直播间，可随时取消拉黑！"
    ></blackConfirm>
    <!-- 自定义列表 -->
    <customList
      v-if="customListStatus"
      :dialogstatus.sync="customListStatus"
      @complete="complete"
    ></customList>
  </div>
</template>
<script>
import allTablelistName from './allTablelistName'
import customList from './customList.vue'
import tagselect from '@/components/tagselect/index'
import category from '@/components/tagselect/category'
import blackConfirm from '@/components/messageBox'

export default {
  name: 'studentAllTable',

  mixins: [allTablelistName],

  components: {
    tagselect,
    blackConfirm,
    category,
    customList,
  },

  data() {
    return {
      ishow: true,

      //自定义的字段
      checkList: [],

      // 自定义列表弹窗
      customListStatus: false,
      // 当前选中的个数
      selectedNum: 0,
      showCategory: false,
      showBlackConfirm: false,

      tableMaxHeight: window.innerHeight - 242,
    }
  },

  props: {
    // 当前列表数据的长度
    tableData: [Object, Array],
    groupList: [Object, Array],
    tagList: [Object, Array],
  },

  watch: {
    selectedNum(val) {
      if (val == this.tableLength) {
        this.checked = true
        this.isIndeterminate = false
      } else if (val < this.tableLength && val > 0) {
        this.isIndeterminate = true
        this.checked = false
      } else {
        this.isIndeterminate = false
        this.checked = false
      }

      this.$emit('statuslist', {
        checked: this.checked,
        isIndeterminate: this.isIndeterminate,
      })
    },
  },

  created() {
    this.getCheckField()
  },

  computed: {
    showList() {
      let newArr = []
      this.checkList.forEach(item => {
        let val = this.allTablelist[item]
        // console.log(val)
        val.label = item
        newArr.push(val)
      })
      return newArr
    },

    tableLength() {
      return this.tableData.length
    },

    showBlackBtn() {
      return this.$store.state.roots.includes(109)
    },
  },

  methods: {
    complete() {
      this.customListStatus = false
      this.getCheckField()
    },

    // 获取显示的字段
    async getCheckField() {
      this.ishow = false
      const { data } = await this.$http({
        url: '/User/getCheckField',
      })
      this.checkList = data
      this.$emit('reset')
      this.ishow = true
    },

    // 自定义列表
    forCustom() {
      this.customListStatus = true
    },

    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },
    selectionChange(val) {
      this.selectedNum = val.length
      this.$emit('selectionChange', val)
    },
    sortChange(value) {
      this.$emit('sortChange', {
        sort: !value.order || value.order === 'ascending' ? 'ASC' : 'DESC',
        order: !value.order ? '' : value.prop,
      })
    },

    // 进入管理页面
    nav(path, { row: { uid } }) {
      this.$router.push({
        path,
        query: {
          uid,
        },
      })
    },

    // 添加黑名单
    blacklist(blackout_reason) {
      this.$http({
        url: '/User/defriend',
        data: {
          uids: [this.showBlackConfirm],
          blackout_reason,
        },
        callback: () => {
          this.$emit('reset')
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.contain {
  padding: 0 20px;
  .custombtn {
    display: flex;
    justify-content: flex-end;
  }
}
.user-info {
  .photo {
    width: 34px;
    height: 34px;
    border-radius: 17px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
span.space {
  color: #0aa29b;
  display: inline-block;
  margin: -3px 5px 0;
}
</style>
