<template>
  <div class="search-contain">
    <el-form
      ref="ruleIngredientsForm"
      label-width="120px"
      :model="search"
      size="small"
      :rules="rules"
    >
      <div class="search_type">
        <el-form-item label="关键词">
          <div class="search-item search-item1 ">
            <div class="vm select">
              <el-select
                v-model="search.searchType"
                size="small"
                style="width:100px"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="vm input">
              <el-input
                v-if="endVal.length <= 0"
                size="small"
                :placeholder="placeholder"
                v-model="search.search1"
                clearable
              ></el-input>
              <el-input
                v-else
                size="small"
                :placeholder="`已输入${endVal.length}条`"
                :disabled="true"
              ></el-input>
            </div>

            <!-- @show="popShow" -->
            <el-popover
              v-if="!search.search1 || (search.search1 && endVal.length > 0)"
              width="518"
              @show="popShow"
              trigger="click"
              popper-class="searchPopperClass"
              placement="bottom-start"
            >
              <div class="pop_contain">
                <div class="pop_title">
                  <strong>
                    批量搜索{{ search.searchType | getsearchType }}
                  </strong>
                </div>
                <div class="pop_body">
                  <div class="tip">
                    注：每条仅可输入一个完整的{{
                      search.searchType | getsearchType
                    }}，不支持模糊搜索，单次最多输入100条。
                  </div>
                  <el-input
                    ref="input1"
                    @keyup.enter.native="show"
                    @keyup.delete.native="show"
                    @input="show2"
                    type="textarea"
                    class="input_textarea"
                    :autosize="{ minRows: 5, maxRows: 4 }"
                    :placeholder="
                      search.searchType == 1 || search.searchType == 2
                        ?'参考示例：\n张三\n李四\n小明'
                        : search.searchType == 4
                        ?'参考示例：\n1334\n1592\n1736'
                        :'参考示例：\n13345671423\n13345677845\n13345676742'
                    "
                    v-model="search.textarea"
                  ></el-input>
                </div>
                <div class="bottom">
                  <div class="number">已输入{{ enterNumber }}条</div>
                  <div class="btns">
                    <el-button size="mini" @click="popCancel">取消</el-button>
                    <el-button size="mini" type="primary" @click="popSubmit">
                      确定
                    </el-button>
                  </div>
                </div>
              </div>
              <el-button
                type="text"
                size="small"
                slot="reference"
                style="margin-left:13px"
              >
                批量搜索
              </el-button>
            </el-popover>
          </div>
        </el-form-item>

        <el-form-item label="学员标签">
          <div class="search-item9">
            <div class="vm select">
              <el-select
                class="placeholder333"
                multiple
                @focus="getTagList"
                v-model="search.tag_ids"
                placeholder="全部"
              >
                <el-option
                  @click.native="otherTag(-1)"
                  label="全部"
                  :value="-1"
                ></el-option>
                <el-option
                  @click.native="otherTag(-2)"
                  label="无标签"
                  :value="-2"
                ></el-option>
                <el-option
                  @click.native="otherTag(item.value)"
                  v-for="item in options4"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="注册来源">
          <div class="vm select">
            <el-select v-model="search.reg_source">
              <el-option
                v-for="item in options7"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="学员分组">
          <div class="vm select">
            <el-select v-model="search.gid">
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="未分组" :value="-2"></el-option>
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">
                  {{ item.label }}
                </span>
                <span style="float: right; color: #999999; font-size: 12px">
                  {{ item.count || 0 }}人
                </span>
              </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="最近消费时间">
          <div class="vm select">
            <el-date-picker
              style="width:100%"
              v-model="search.last_buy_time"
              @change="
                selectedTime(arguments[0], [
                  'small_last_buy_time',
                  'large_last_buy_time',
                ])
              "
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              unlink-panels
              value-format="timestamp"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="消费金额" prop="large_amount">
          <div class="search-item8">
            <div class="vm select">
              <div class="flex-center" style="width:100%">
                <el-input
                  :value="search.small_amount"
                  @input="search.small_amount = toNumber(arguments[0])"
                  clearable
                >
                  <template slot="append">元</template>
                </el-input>
                <span class="middle-space">至</span>
                <el-input
                  :value="search.large_amount"
                  @input="search.large_amount = toNumber(arguments[0])"
                  clearable
                >
                  <template slot="append">元</template>
                </el-input>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- <el-form-item label="用户身份">
          <div class="vm select">
            <el-select v-model="search.gid">
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="未分组" :value="-2"></el-option>
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">
                  {{ item.label }}
                </span>
                <span style="float: right; color: #999999; font-size: 12px">
                  {{ item.count || 0 }}人
                </span>
              </el-option>
            </el-select>
          </div>
        </el-form-item> -->

        <el-form-item label="绑定手机">
          <div class="vm select">
            <el-select v-model="search.is_umobile">
              <el-option
                v-for="item in options6"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>

        <!-- :picker-options="pickerOptions" -->

        <el-form-item label="注册时间">
          <div class="vm select">
            <el-date-picker
              v-model="search.registerTime"
              @change="
                selectedTime(arguments[0], ['small_regdate', 'large_regdate'])
              "
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              value-format="timestamp"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="登录次数" prop="large_logincount">
          <div class="search-item8">
            <div class="vm select">
              <div class="flex-center" style="width:100%">
                <el-input
                  :value="search.small_logincount"
                  @input="
                    search.small_logincount = toNumber(arguments[0], true)
                  "
                  clearable
                >
                  <template slot="append">次</template>
                </el-input>
                <span class="middle-space">至</span>
                <el-input
                  :value="search.large_logincount"
                  @input="
                    search.large_logincount = toNumber(arguments[0], true)
                  "
                  clearable
                >
                  <template slot="append">次</template>
                </el-input>
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- <el-form-item label="所属推广员">
          <div class="vm select">
            <el-select v-model="search.gid">
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="未分组" :value="-2"></el-option>
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">
                  {{ item.label }}
                </span>
                <span style="float: right; color: #999999; font-size: 12px">
                  {{ item.count || 0 }}人
                </span>
              </el-option>
            </el-select>
          </div>
        </el-form-item> -->
        <el-form-item label="微信openid">
          <el-input
            v-model="search.openid"
            clearable
            @input="search.openid = search.openid.replace(/^\s/g, '')"
            size="small"
          ></el-input>
        </el-form-item>

        <!-- :picker-options="pickerOptions" -->
        <el-form-item label="首次支付时间">
          <div class="vm select">
            <el-date-picker
              v-model="search.first_buy_time"
              @change="
                selectedTime(arguments[0], [
                  'small_first_buy_time',
                  'large_first_buy_time',
                ])
              "
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              value-format="timestamp"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="年龄">
          <el-input
            v-model="search.age"
            maxlength="30"
            clearable
            size="small"
            @input="search.age = search.age.replace(/^\s/g, '')"
          ></el-input>
        </el-form-item>

        <template v-if="!isClose">
          <el-form-item label="地区">
            <div class="vm select">
              <!-- <el-select multiple v-model="search.area">
              <el-option
                v-for="item in adddress"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select> -->

              <el-cascader
                style="width:100%"
                v-model="search.area"
                clearable
                :options="city"
                placeholder="请选择"
              ></el-cascader>
            </div>
          </el-form-item>

          <!-- :picker-options="pickerOptions" -->
          <el-form-item label="最后登录时间">
            <div class="vm select">
              <el-date-picker
                v-model="search.loginTime"
                @change="
                  selectedTime(arguments[0], [
                    'small_lastdate',
                    'large_lastdate',
                  ])
                "
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                align="right"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="性别">
            <div class="vm select">
              <el-select v-model="search.usex">
                <el-option
                  v-for="item in options8"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="职业">
            <div class="vm select">
              <el-input
                maxlength="30"
                v-model="search.position"
                clearable
                size="small"
                @input="search.position = search.position.replace(/^\s/g, '')"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item label="消费次数" prop="large_order_quantity">
            <div class="vm search-item search-item8">
              <div class="vm select">
                <div class="flex-center" style="width:100%">
                  <el-input
                    :value="search.small_order_quantity"
                    @input="
                      search.small_order_quantity = toNumber(arguments[0], true)
                    "
                    clearable
                  >
                    <template slot="append">次</template>
                  </el-input>
                  <span class="middle-space">至</span>
                  <el-input
                    :value="search.large_order_quantity"
                    @input="
                      search.large_order_quantity = toNumber(arguments[0], true)
                    "
                    clearable
                  >
                    <template slot="append">次</template>
                  </el-input>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="是否付费">
            <div class="vm select">
              <el-select v-model="search.is_pay">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="机构(单位)">
            <el-input
              v-model="search.company"
              clearable
              @input="search.company = search.company.replace(/^\s/g, '')"
              maxlength="30"
              size="small"
            ></el-input>
          </el-form-item>
        </template>
      </div>
      <div class="operation">
        <div class="flex-center" style="margin-top: 10px">
          <el-button
            size="medium"
            @click="tosearch ? filterStart() : ''"
            type="primary"
            class="filter-button"
          >
            筛选
          </el-button>
          <div @click="init" class="filter-text mr20">重置</div>
          <el-button
            size="medium"
            type="text"
            @click="searchOpenClose"
            v-if="!isClose"
            style="margin-top: -3px;"
          >
            收起
            <i class="el-icon-arrow-up el-icon--right"></i>
          </el-button>
          <el-button
            size="medium"
            v-else
            type="text"
            @click="searchOpenClose"
            style="margin-top: -3px;"
          >
            展开
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </div>

        <div v-if="showExportBtn" class="exportTable">
          <el-button
            v-if="selection.length > 0"
            @click="exportTable(1)"
            size="medium"
            type="primary"
          >
            导出选中
          </el-button>
          <el-button size="medium" @click="exportTable(2)" type="primary">
            导出
          </el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import allTablelistName from './allTablelistName'
import selectcities from '@/mixin/selectcities'
export default {
  name: 'studentListSearch',

  mixins: [allTablelistName, selectcities],

  props: {
    pageType: [Number, String],

    selection: Array,
    tableDataTotal: Number,
    isExportAll:Boolean,
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (
        this.search.large_amount &&
        Number(this.search.large_amount) < Number(this.search.small_amount)
      ) {
        this.tosearch = false
        callback(new Error('最大金额不能小于最小金额'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (
        typeof this.search.large_logincount === 'number' &&
        this.search.large_logincount < this.search.small_logincount
      ) {
        this.tosearch = false
        callback(new Error('最大次数不能小于最小次数'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    var validatePass3 = (rule, value, callback) => {
      if (
        typeof this.search.large_order_quantity === 'number' &&
        this.search.large_order_quantity < this.search.small_order_quantity
      ) {
        this.tosearch = false
        callback(new Error('最大次数不能小于最小次数'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    return {
      tosearch: true,
      rules: {
        large_amount: [{ validator: validatePass, trigger: 'blur' }],
        large_logincount: [{ validator: validatePass2, trigger: 'blur' }],
        large_order_quantity: [{ validator: validatePass3, trigger: 'blur' }],
      },

      inputval: [],

      endVal: '',

      // 批量搜索框回車次數
      enterNumber: 0,

      // 展开收起的状态
      isClose: true,

      search: {},

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            },
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            },
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            },
          },
        ],
      },

      // 搜索-
      options1: [
        { label: '昵称', value: 1, placeholder: '输入学员昵称' },
        { label: '真实姓名', value: 2, placeholder: '输入真实姓名' },
        { label: '手机号', value: 3, placeholder: '输入手机号' },
        { label: '用户id', value: 4, placeholder: '输入用户id' },
      ],

      // 是否付费
      options2: [
        { label: '全部', value: 0 },
        { label: '是', value: 1 },
        { label: '否', value: 2 },
      ],

      // 用户注册来源
      options7: [
        { label: '全部', value: 0 },
        { label: 'h5网校', value: 1 },
        { label: '叮叮开课直播小程序', value: 2 },
        { label: '推广招生-抖音广告', value: 3 },
      ],

      options6: [
        { label: '全部', value: 0 },
        { label: '已绑定', value: 1 },
        { label: '未绑定', value: 2 },
      ],
      // 学员分组
      options3: [],

      // 学员标签
      options4: [],

      // 性别
      options8: [
        { label: '全部', value: 0 },
        { label: '男', value: '男' },
        { label: '女', value: '女' },
      ],
    }
  },

  watch: {
    inputval(val) {
      this.enterNumber = val.length
    },

    'search.is_pay'(value) {
      if (value == 2) {
        this.search = _.assign({}, this.search, {
          small_amount: '',
          large_amount: '',
        })
      }
    },
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.searchType)
        .placeholder
    },

    showExportBtn() {
      return this.$store.state.roots.includes(107)
    },
  },

  filters: {
    getsearchType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '昵称'
          break
        case 2:
          text = '真实姓名'
          break
        case 3:
          text = '手机号'
          break
        case 4:
          text = '用户id'
          break
      }
      return text
    },
  },

  created() {
    this.init()
  },

  methods: {
    // 控制展开和收起
    searchOpenClose() {
      this.isClose = !this.isClose
    },

    // 提交批量筛选的弹窗的内容
    popSubmit() {
      this.endVal = this.$refs.input1.value.split(/\n/)

      if (this.endVal.length > 100) {
        this.$root.prompt('单次最多输入100条')
        return
      }

      if (this.endVal.length > 0) {
        if (!this.endVal[0] && this.endVal.length == 1) {
          this.resetall()
        } else {
          this.search.search = this.endVal
        }
      } else {
        this.resetall()
      }
      this.popCancel()
    },

    resetall() {
      this.endVal = []
      this.search.search = []
      this.inputval = []
    },

    // 关闭批量筛选的弹窗
    popCancel() {
      document.body.click()
    },

    popShow() {
      this.$nextTick(() => {
        this.$refs.input1.focus()
      })
    },

    show2(val) {
      if (this.enterNumber == 0 && val.length > 0) {
        this.enterNumber += 1
      }
    },

    show() {
      const value = this.$refs.input1.value
      this.inputval = value.split(/\n/)

      if (this.inputval.length == 1 && this.inputval[0] == '') {
        this.inputval = []
      }
    },

    init() {
      //初始化搜索条件
      this.baseSeachOption()
      this.reset()
      // 第一次显示全部 不填充-1
      this.search.tag_ids = []
      this.enterNumber = 0
      this.endVal = []
    },

    // 设置默认搜索条件
    baseSeachOption() {
      this.search = {
        type: this.pageType, // 1全部  2今日访问 3黑名单
        order: '', // 排序字段
        sort: 'ASC', // 排序类型  DESC 倒序   ASC 正序
        searchType: 1, // 搜索类型 1.微信名 2真实姓名 3手机号
        search1: '',
        search: [], // 搜索内容
        gid: -1, // 分组ID
        tag_ids: [], // 标签id集合
        is_pay: 0, // 是否付费   0全部 1是 2否
        reg_source: 0, // 1 h5网校 2 叮叮开课直播小程序 3 推广招生-抖音广告
        is_umobile: 0, //绑定手机  0全部 1是 2否
        loginTime: 0,
        area: [], //地区
        blackListTime: 0, // 拉黑时间
        small_amount: '', // 最小付费金额
        large_amount: '', // 最大付费金额
        registerTime: 0,
        small_last_buy_time: '', //最近消费时间 (小)
        large_last_buy_time: '', // 最近消费时间(大)
        last_buy_time: 0,
        small_first_buy_time: '', //首次支付时间(小)
        large_first_buy_time: '', // 首次支付时间(大)
        first_buy_time: 0,
        usex: 0, //性别
        position: '', //职业
        small_regdate: 0, // 最早注册时间
        large_regdate: 0, // 最后注册时间
        small_lastdate: 0, // 最早登录时间
        large_lastdate: 0, // 最后登录时间
        small_logincount: '', // 最小登录次数
        large_logincount: '', // 最大登录次数
        small_order_quantity: '', //最小消费次数
        large_order_quantity: '', //最大消费次数
        small_quantity: '', // 最小购买次数
        large_quantity: '', // 最大购买次数
        age: '', //年龄
        openid: '', //微信id
        company: '', //机构单位
      }
    },

    // 设置搜索条件参数
    setSearchOption(option) {
      this.search = _.merge({}, this.search, option)
      this.filterStart()
    },

    // 导出
    exportTable(type) {
      const verify = type == 1 ? this.selection.length : this.tableDataTotal
      const confirmStr = this.isExportAll?'确定要导出所有数据？':`确定要导出当前${verify}条数据？`
      if (verify > 0) {
        this.$confirm(confirmStr, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(
            _.assign({}, this.search, {
              uids: type == 1 ? this.selection : undefined,
            })
          )

          this.$store.dispatch(
            'open',
            `${location.origin}/User/exportExcel?query=${encodeURIComponent(
              query
            )}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    // 筛选前填充数据
    fullOption() {
      const t = this.search
      // 设置默认值
      this.search = _.assign({}, this.search, {
        tag_ids: t.tag_ids.length == 0 ? [-1] : t.tag_ids,
        // 最小付费金额
        small_amount: t.small_amount || (t.large_amount ? '0' : ''),
        large_amount: t.large_amount || (t.small_amount ? '0' : ''), // 最大付费金额

        small_regdate: t.small_regdate || t.large_regdate || 0, // 最早注册时间
        large_regdate: t.large_regdate || t.small_regdate || 0, // 最后注册时间
        small_operator_time:
          t.small_operator_time || t.large_operator_time || 0, // 最早拉黑时间
        large_operator_time:
          t.large_operator_time || t.small_operator_time || 0, // 最后拉黑时间
        small_lastdate: t.small_lastdate || t.large_lastdate || 0, // 最早登录时间
        large_lastdate: t.large_lastdate || t.small_lastdate || 0, // 最后登录时间
        small_logincount:
          t.small_logincount === 0
            ? 0
            : t.small_logincount ||
              (typeof t.large_logincount === 'number' ? 0 : ''), // 最小登录次数
        large_logincount:
          t.large_logincount ||
          (typeof t.small_logincount === 'number' ? 0 : ''), // 最大登录次数
        small_order_quantity:
          t.small_order_quantity === 0
            ? 0
            : t.small_order_quantity ||
              (typeof t.large_order_quantity === 'number' ? 0 : ''), // 最小消费次数
        large_order_quantity:
          t.large_order_quantity ||
          (typeof t.small_order_quantity === 'number' ? 0 : ''), // 最大消费次数
        small_quantity: t.small_quantity || t.large_quantity || '', // 最小购买次数
        large_quantity: t.large_quantity || t.small_quantity || '', // 最大购买次数
      })
    },

    // 筛选
    filterStart() {
      this.fullOption()
      if (this.$refs['ruleIngredientsForm']) {
        this.$refs['ruleIngredientsForm'].validate(valid => {
          if (valid) {
            this.$emit('search', _.merge({}, this.search))
          } else {
            return false
          }
        })
      } else {
        this.$emit('search', _.merge({}, this.search))
      }
    },

    otherTag(value) {
      if (value < 0 || this.search.tag_ids[0] < 0) {
        this.search.tag_ids = [value]
      }
    },

    // 清空筛选选项
    async reset() {
      this.filterStart()
      // 获取机构标签
      this.getTagList()
      // 获取分组列表
      this.getGroupList()
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },

    // 获取机构标签
    async getTagList() {
      const { data } = await this.$http({
        url: '/UserTag/getJigouTag',
      })
      this.options4 = data.map(item => {
        return { label: item.tag_name, value: Number(item.tag_id) }
      })
      this.$emit('setTagList', this.options4)
    },

    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          this.options3 = data.map(item => {
            return {
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            }
          })
          this.$emit('setGroupList', this.options3)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .vm {
  display: flex !important;
}
.search-contain {
  font-size: 0;
  position: relative;
  padding: 30px 20px 0px 0px;
}
.search_type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .el-form-item__content {
    height: auto;
  }
  .el-form-item {
    width: 33.3%;
    margin-bottom: 20px;
  }
}
.middle-space {
  font-size: 14px;
  line-height: 19px;
  margin: 0 10px;
  color: rgba(51, 51, 51, 1);
}

.search-text {
  min-width: 56px;
  font-size: 14px;
  line-height: 19px;
  margin-right: 10px;
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input,
::v-deep .select {
  width: 100%;
  .el-input__inner {
    width: 100%;
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
}
.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}
.filter-text {
  font-size: 14px;
  line-height: 19px;
  margin-left: 20px;
  cursor: pointer;
  color: rgba(10, 162, 155, 1);
}
::v-deep .select {
  .el-select {
    display: block;
    width: 100%;
  }
}

::v-deep .search-item1 {
  display: flex;
  .select {
    width: 100px;
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .input {
    width: 100%;
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
::v-deep .search-item2,
::v-deep .search-item6 {
  .select {
    width: 160px;
  }
}
::v-deep .search-item3,
::v-deep .search-item4 {
  .el-date-editor {
    width: 292px;
  }
  .el-input__icon {
    line-height: 27px;
  }
}
::v-deep .search-item5,
::v-deep .search-item7,
::v-deep .search-item8 {
  .el-input {
    // width: 130px;
    position: relative;
    input[type='number'] {
      padding-right: 50px;
    }
    .el-input-group__append {
      position: absolute;
      right: 0;
      background: none;
      top: 6px;
      height: 100%;
      border: 0;
      // line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }
}
.el-button {
  line-height: initial;
}
::v-deep .filter-button,
::v-deep .exportTable .el-button {
  width: 100px;
  // height: 36px;
  // line-height: 10px;
}
::v-deep .placeholder333 {
  input::-webkit-input-placeholder {
    color: #333;
  }
}
</style>

<style lang="scss">
.searchPopperClass {
  .pop_contain {
    .pop_title {
      margin-bottom: 8px;
    }
    .pop_body {
      margin-bottom: 16px;
      .tip {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .input_textarea {
        textarea {
          @extend %scroll;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .number {
        font-size: 14px;
      }
    }
  }
}
</style>
