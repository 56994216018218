/*
 * @Author: cyy
 * @version:
 * @Date: 2022-06-24 16:02:29
 * @LastEditTime: 2022-06-27 14:22:35
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \teacher.dingdingkaike.com\src\views\teachingAdmin\teachingAdmin\allTablelistName.js
 */
export default {
  data() {
    return {
      allTablelist: {
        uid: {
          id: Math.random(),
          name: '用户ID',
          sort: false,
        },
        umobile: {
          id: Math.random(),
          name: '手机号',
          sort: false,
        },
        student_remarks: {
          id: Math.random(),
          name: '真实姓名',
          sort: false,
        },
        reg_source: {
          id: Math.random(),
          name: '注册来源',
          sort: false,
        },
        regdate: {
          id: Math.random(),
          name: '注册时间',
          sort: true,
        },
        usex: {
          id: Math.random(),
          name: '性别',
          sort: false,
        },
        age: {
          id: Math.random(),
          name: '年龄',
          sort: false,
        },
        area: {
          id: Math.random(),
          name: '地区',
          sort: false,
        },
        position: {
          id: Math.random(),
          name: '职业',
          sort: false,
        },
        company: {
          id: Math.random(),
          name: '机构(单位)',
          sort: false,
        },
        uemail: {
          id: Math.random(),
          name: '邮箱',
          sort: false,
        },
        wx_number: {
          id: Math.random(),
          name: '微信号',
          sort: false,
        },
        gname: {
          id: Math.random(),
          name: '分组',
          sort: false,
        },
        tag: {
          id: Math.random(),
          name: '标签',
          sort: false,
        },
        openid: {
          id: Math.random(),
          name: '微信openid',
          sort: false,
        },
        order_quantity: {
          id: Math.random(),
          name: '消费次数',
          sort: true,
        },
        order_amount: {
          id: Math.random(),
          name: '消费金额(元)',
          sort: true,
        },
        last_buy_time: {
          id: Math.random(),
          name: '最近消费时间',
          sort: true,
        },
        lastdate: {
          id: Math.random(),
          name: '最后登录时间',
          sort: true,
        },
        first_buy_time: {
          id: Math.random(),
          name: '首次支付时间',
          sort: false,
        },
        logincount: {
          id: Math.random(),
          name: '登录次数',
          sort: true,
        },
      },
      adddress: [
        { label: '北京', value: 0 },
        { label: '天津', value: 1 },
        { label: '上海', value: 2 },
        { label: '重庆', value: 3 },
        { label: '河北', value: 4 },
        { label: '山西', value: 5 },
        { label: '辽宁', value: 6 },
        { label: '吉林', value: 7 },
        { label: '黑龙江', value: 8 },
        { label: '江苏', value: 9 },
        { label: '浙江', value: 10 },
        { label: '安徽', value: 11 },
        { label: '福建', value: 12 },
        { label: '江西', value: 13 },
        { label: '山东', value: 14 },
        { label: '河南', value: 15 },
        { label: '湖北', value: 16 },
        { label: '湖南', value: 17 },
        { label: '广东', value: 18 },
        { label: '海南', value: 19 },
        { label: '四川', value: 20 },
        { label: '贵州', value: 21 },
        { label: '云南', value: 22 },
        { label: '陕西', value: 23 },
        { label: '甘肃', value: 24 },
        { label: '青海', value: 25 },
        { label: '内蒙古', value: 26 },
        { label: '广西', value: 27 },
        { label: '西藏', value: 28 },
        { label: '宁夏', value: 29 },
        { label: '新疆', value: 30 },
        { label: '香港', value: 31 },
        { label: '澳门', value: 32 },
        { label: '台湾', value: 33 },
      ],
    }
  },
}
