<template>
  <div class="contain">
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: 'rgba(245,245,245,1)',
        color: '#333333',
      }"
      @select-all="selectAll"
      ref="tablelist"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" />
      <el-table-column prop="uid" label="用户ID"></el-table-column>
      <el-table-column prop="name" label="学员">
        <template slot-scope="scope">
          <div class="flex-center user-info">
            <img class="photo" :src="scope.row.uphoto" />
            <span class="uname">{{ scope.row.uname || '--' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="真实姓名" prop="student_remarks">
        <template slot-scope="scope">
          {{ scope.row.student_remarks || '- -' }}
        </template>
      </el-table-column>
      <el-table-column prop="umobile" label="手机号">
        <template slot-scope="scope">
          {{ scope.row.umobile || '- -' }}
        </template>
      </el-table-column>
      <el-table-column prop="tag" label="标签">
        <template slot-scope="scope">
          <tagselect
            :uids="scope.row.uid"
            :tag="scope.row.tag"
            :isCustom="true"
          >
            <template v-slot:reference="{ tag }">
              <el-button type="text">
                {{ !tag ? '设置' : tag.split(',').length + '个标签' }}
              </el-button>
            </template>
          </tagselect>
        </template>
      </el-table-column>
      <el-table-column label="分组" prop="gname">
        <template slot-scope="scope">
          {{ scope.row.gname || '- -' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="regdate"
        label="注册时间"
        sortable="custom"
        min-width="110"
      >
        <template slot-scope="scope">
          {{ scope.row.regdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}
        </template>
      </el-table-column>
      <el-table-column
        prop="operator_time "
        label="拉黑时间"
        sortable="custom"
        min-width="110"
      >
        <template slot-scope="scope">
          {{ scope.row.operator_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
        </template>
      </el-table-column>
      <el-table-column prop="blackout_reason" label="拉黑原因">
        <template slot-scope="scope">
          {{ scope.row.blackout_reason || '无' }}
        </template>
      </el-table-column>
      <el-table-column prop="operator_name" label="拉黑人"></el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <div class="flex-center">
            <el-button
              type="text"
              size="small"
              @click="nav('/manageStudent/detail', scope)"
            >
              详情
            </el-button>
            <span class="space">|</span>
            <el-button
              type="text"
              size="small"
              @click="moveOutBlackList(scope.row)"
            >
              移出黑名单
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import tagselect from '@/components/tagselect/index'

export default {
  name: 'blockTableData',

  components: {
    tagselect,
  },

  data() {
    return {
      // 当前选中的个数
      selectedNum: 0,
    }
  },

  computed: {
    tableLength() {
      return this.tableData.length
    },
  },

  watch: {
    selectedNum(val) {
      if (val == this.tableLength) {
        this.checked = true
        this.isIndeterminate = false
      } else if (val < this.tableLength && val > 0) {
        this.isIndeterminate = true
        this.checked = false
      } else {
        this.isIndeterminate = false
        this.checked = false
      }

      this.$emit('statuslist', {
        checked: this.checked,
        isIndeterminate: this.isIndeterminate,
      })
    },
  },

  props: {
    tableData: [Object, Array],
    groupList: [Object, Array],
    tagList: [Object, Array],
  },

  methods: {
    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },
    selectionChange(val) {
      this.selectedNum = val.length
      this.$emit('selectionChange', val)
    },
    sortChange(value) {
      this.$emit('sortChange', {
        sort: !value.order || value.order === 'ascending' ? 'ASC' : 'DESC',
        order: !value.order ? '' : value.prop,
      })
    },

    // 进入管理页面
    nav(path, { row: { uid } }) {
      this.$router.push({
        path,
        query: {
          uid,
        },
      })
    },

    // 移出黑名单
    moveOutBlackList({ uid }) {
      this.$confirm('确定将当前选中的学员移出黑名单？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url: '/User/befriend',
          data: {
            uids: [uid],
          },
          callback: () => {
            this.$emit('reset')
          },
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.contain {
  padding: 0 20px;
}
.user-info {
  .photo {
    width: 34px;
    height: 34px;
    border-radius: 17px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
span.space {
  color: #0aa29b;
  display: inline-block;
  margin: -3px 10px 0;
}
</style>
