<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-24 10:00:16
 * @LastEditTime: 2022-06-29 17:21:15
 * @LastEditors: cyy
 * @Description: 全部学员自定义列表
 * @FilePath: \teacherdingding.com\src\views\teachingAdmin\teachingAdmin\customList.vue
-->
<template>
  <div id="customList">
    <el-dialog
      title="自定义列表"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="select_area">
          <div class="header">可选字段</div>
          <div class="contain">
            <div class="title mb20">用户信息</div>
            <!-- {{ checkList }} -->
            <el-checkbox-group v-model="checkList" class="check_list">
              <el-checkbox label="uid">用户ID</el-checkbox>
              <el-checkbox label="umobile">手机号</el-checkbox>
              <el-checkbox label="student_remarks">真实姓名</el-checkbox>
              <el-checkbox label="reg_source">注册来源</el-checkbox>
              <el-checkbox label="regdate">注册时间</el-checkbox>
              <el-checkbox label="usex">性别</el-checkbox>
              <el-checkbox label="position">职业</el-checkbox>
              <el-checkbox label="age">年龄</el-checkbox>
              <el-checkbox label="area">地区</el-checkbox>
              <el-checkbox label="uemail">邮箱</el-checkbox>
              <el-checkbox label="company">机构(单位)</el-checkbox>
              <el-checkbox label="tag">标签</el-checkbox>
              <el-checkbox label="openid">微信openid</el-checkbox>
              <el-checkbox label="wx_number">微信号</el-checkbox>
              <el-checkbox label="gname">分组</el-checkbox>
            </el-checkbox-group>

            <div class="title mb20  mt10">行为统计</div>
            <el-checkbox-group v-model="checkList" class="check_list">
              <el-checkbox label="order_quantity">消费次数</el-checkbox>
              <el-checkbox label="order_amount">消费金额(元)</el-checkbox>
              <el-checkbox label="last_buy_time">最近消费时间</el-checkbox>
              <el-checkbox label="lastdate">最后登录时间</el-checkbox>
              <el-checkbox label="first_buy_time">首次支付时间</el-checkbox>
              <el-checkbox label="logincount">登录次数</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="show_area">
          <div class="header">已选字段</div>
          <div class="list_contain">
            <vuedraggable v-model="checkList" :animation="200">
              <transition-group>
                <div class="item" v-for="i in showList" :key="i.id">
                  <div class="left">
                    <img src="@ass/img/1.4.13/icon_yxzdtz.svg" alt="" />
                    <div class="name">{{ i.name }}</div>
                  </div>
                  <div class="close" @click="del(i)">
                    <i class="el-icon-close"></i>
                  </div>
                </div>
              </transition-group>
            </vuedraggable>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button type="text" class="redcolor" @click="recovery">
          恢复默认设置
        </el-button>
        <div class="rightbtn">
          <el-button style="width:86px" size="medium" @click="cancel">
            取消
          </el-button>
          <el-button
            style="width:86px"
            type="primary"
            size="medium"
            @click="submit"
          >
            确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import allTablelistName from './allTablelistName'
import vuedraggable from 'vuedraggable'
export default {
  name: 'customList',

  mixins: [allTablelistName],

  data() {
    return {
      checkList: [],
      defaultlist: [
        'uid',
        'student_remarks',
        'umobile',
        'tag',
        'gname',
        'order_amount',
        'order_quantity',
        'logincount',
        'reg_source',
        'lastdate',
        'regdate',
      ],
    }
  },

  computed: {
    showList() {
      let newArr = []
      this.checkList.forEach(item => {
        let val = this.allTablelist[item]
        val.label = item
        newArr.push(val)
      })
      return newArr
    },
  },

  components: {
    vuedraggable,
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getCheckField()
  },

  methods: {
    // 恢复默认
    recovery() {
      this.checkList = this.defaultlist
    },

    async getCheckField() {
      const { data } = await this.$http({
        url: '/User/getCheckField',
      })
      this.checkList = data
    },

    // 删除选中项
    del(val) {
      const index = this.checkList.findIndex(item => item == val.label)
      console.log(val, index, this.checkList)
      this.checkList.splice(index, 1)
    },

    async submit() {
      if (this.checkList.length == 0) {
        this.$root.prompt('自定义列表不能为空！')
        return
      }
      await this.$http({
        url: '/User/saveCheckField',
        data: {
          data: this.checkList,
        },
        successMsg: true,
      })

      this.$emit('complete', false)
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
#customList {
  .dialogVisible {
    ::v-deep .redcolor {
      &.el-button--text:hover {
        color: #ff3535 !important;
      }
    }

    ::v-deep .el-dialog {
      height: 542px;
      .el-dialog__body {
        padding: 10px 20px 20px;
      }
    }
    ::v-deep .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    ::v-deep .el-dialog__header {
      padding-top: 14px;
    }
    .dialogVisible_bottom {
      margin: 0 auto;
      display: flex;
      .header {
        height: 40px;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 14px;
        color: #333333;
      }
      .select_area {
        border: #f5f5f5 1px solid;
        width: 460px;
        height: 414px;
        flex-shrink: 0;
        margin-right: 20px;
        .contain {
          padding: 20px;
          ::v-deep .el-checkbox {
            width: 33%;
            margin-right: 0;
            margin-bottom: 16px;
          }
          .check_list {
            display: flex;
            flex-wrap: wrap;
          }
          .title {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
      .show_area {
        border: #f5f5f5 1px solid;
        height: 414px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .list_contain {
          overflow: auto;
          @extend %scroll;
          flex: 1;
          .item {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: move;
            border-bottom: 1px solid #f5f5f5;
            .left {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
              }
              .name {
                font-size: 14px;
                color: #333333;
              }
            }
            .close {
              width: 12px;
              height: 12px;
              color: #999999;
              padding: 5px;
              cursor: pointer;
            }
            &:hover {
              background-color: #f0f7ff;
            }
          }
        }
      }
    }
    .btns {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
