<template>
  <div style="min-width:1067px">
    <template v-if="$route.path === '/manageStudent'">
      <el-card class="box-card">
        <div class="item">
          <el-tabs v-model="activeName" @tab-click="changeTabs">
            <el-tab-pane label="全部" name="one">
              <tableData :pageType="type" v-if="activeName == 'one'" />
            </el-tab-pane>
            <!-- <el-tab-pane label="今日访问" name="four">
              <tableData :type="type" v-if="activeName == 'four'" />
            </el-tab-pane> -->
            <el-tab-pane label="黑名单" name="five">
              <tableData :pageType="type" v-if="activeName == 'five'" />
            </el-tab-pane>
            <el-tab-pane label="标签管理" name="six">
              <TagTableData v-if="activeName == 'six'" />
            </el-tab-pane>
            <el-tab-pane label="学员分组" name="seven">
              <groupManagement v-if="activeName == 'seven'" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>
    </template>
    <router-view />
  </div>
</template>
<script>
import tableData from './teachingAdmin/tableData'
import TagTableData from './teachingAdmin/TagTableData' //标签
import groupManagement from './teachingAdmin/groupManagement' //分组管理
import background from '@/mixin/background'

export default {
  components: {
    tableData,
    TagTableData,
    groupManagement,
  },

  mixins: [background],

  data() {
    return {
      activeName: 'one', //默认全部
      type: 1, // 默认显示全部
      tabsData: [
        { label: '全部', name: 'first' },
        // { label: '今日访问', name: 'four' },
        { label: '黑名单', name: 'five' },
        { label: '标签管理', name: 'six' },
        { label: '学员分组', name: 'seven' },
      ],
    }
  },

  created() {
    if (this.$route.query.type) {
      switch (Number(this.$route.query.type)) {
        case 1:
          this.activeName = 'five'
          break
        case 2:
          this.activeName = 'six'
          break
        case 3:
          this.activeName = 'seven'
          break
      }
      switch (this.activeName) {
        case 'one':
          this.type = 1
          break
        default:
          this.type = 3
      }
    }
  },
  methods: {
    changeTabs() {
      switch (this.activeName) {
        case 'one':
          this.type = 1
          break
        default:
          this.type = 3
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box-card {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
}
.el-button--text {
  color: #1b9d97;
}
/deep/.el-card__body {
  padding: 0px !important;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header.is-top {
  margin: 0px;
}
/deep/ .el-tabs__item.is-active {
  color: #0aa29b;
}
/deep/ .el-tabs__item:hover {
  color: #0aa29b;
}

/deep/ .el-tabs__active-bar {
  background-color: #0aa29b;
  height: 1px;
}
// /deep/ .el-tabs.el-tabs--top {
// }
/deep/ #tab-first {
  padding-left: 20px;
}
.el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
// /deep/ .el-input.el-input--suffix {
//   // width: 120px;
// }
/deep/ #tab-one {
  padding-left: 20px !important;
}
</style>
