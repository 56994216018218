<template>
  <div>
    <search
      v-if="pageType == 1"
      ref="search"
      :selection="selection"
      :pageType="pageType"
      :tableDataTotal="tableDataTotal"
      :isExportAll="false"
      @search="searchOption = arguments[0]"
      @setTagList="tagList = arguments[0]"
      @setGroupList="groupList = arguments[0]"
    ></search>

    <search2
      v-else
      ref="search"
      :selection="selection"
      :pageType="pageType"
      :tableDataTotal="tableDataTotal"
      @search="searchOption = arguments[0]"
      @setTagList="tagList = arguments[0]"
      @setGroupList="groupList = arguments[0]"
    ></search2>

    <pagination2
      :option="searchOption"
      url="/User/getStudentList"
      @complete="tableDataTotal = arguments[0].total"
    >
      <template v-slot:default="{ tableData }">
        <component
          :is="componentId"
          :tableData="tableData"
          :ref="componentId"
          :tagList="tagList"
          :groupList="groupList"
          @statuslist="statuslist"
          @sortChange="$refs.search.setSearchOption(arguments[0])"
          @reset="tableDataReset"
          @selectionChange="selection = arguments[0].map(item => item.uid)"
        ></component>
      </template>
      <template v-slot:footer>
        <div ref="test" class="flex-center footer-button">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checked"
            @change="boxChange($event)"
          >
            <div>已选择 {{ selection.length }}条</div>
          </el-checkbox>
          <div class="t2">
            <el-select
              class="placeholder333"
              v-model="selectedTeam"
              @change="bindUserTag"
              placeholder="批量分组"
            >
              <el-option label="无" :value="0"></el-option>
              <el-option
                v-for="item in groupList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">
                  {{ item.label }}
                </span>
                <span style="float: right; color: #999999; font-size: 12px">
                  {{ item.count || 0 }}人
                </span>
              </el-option>
            </el-select>
          </div>
          <div class="t3">
            <tagselect
              :uids="selection"
              :multiple="true"
              :isCustom="true"
              :noCancel="true"
              @reset="tableDataReset"
            >
              <template v-slot:reference>
                <el-button
                  @click.stop="
                    $root.prompt({
                      msg: '请先选择学员，方可批量操作',
                      type: 'info',
                    })
                  "
                  v-if="selection.length == 0"
                >
                  批量贴标签
                </el-button>
                <el-button v-else>批量贴标签</el-button>
              </template>
            </tagselect>
          </div>
          <template v-if="pageType == 1 && showBlackBtn">
            <div class="t4">
              <el-button
                @click.stop="
                  $root.prompt({
                    msg: '请先选择学员，方可批量操作',
                    type: 'info',
                  })
                "
                v-if="selection.length == 0"
              >
                批量拉黑
              </el-button>
              <el-button @click="showBlackConfirm = true" v-else>
                批量拉黑
              </el-button>
            </div>
          </template>
          <template v-else-if="pageType != 1">
            <div class="t4">
              <el-button
                @click.stop="
                  $root.prompt({
                    msg: '请先选择学员，方可批量操作',
                    type: 'info',
                  })
                "
                v-if="selection.length == 0"
              >
                批量移出黑名单
              </el-button>
              <el-button @click="moveOutBlackList" v-else>
                批量移出黑名单
              </el-button>
            </div>
          </template>
        </div>
      </template>
    </pagination2>
    <!--拉黑-->
    <blackConfirm
      title="拉黑"
      v-if="showBlackConfirm"
      @close="showBlackConfirm = false"
      @complete="blacklist"
      placeholder="输入拉黑原因，选填"
      content="拉黑后学员无法进入网校、查看课程、报名课程，进入直播间，可随时取消拉黑！"
    ></blackConfirm>
  </div>
</template>
<script>
import search from './search'
import search2 from './search2'
import allTable from './allTable'
import blockTableData from './blockTableData' // 黑名单
import tagselect from '@/components/tagselect/index'
import blackConfirm from '@/components/messageBox'

export default {
  name: 'studentTabList',

  props: {
    pageType: [Number, String],
  },

  components: {
    search,
    tagselect,
    allTable,
    blackConfirm,
    blockTableData,
    search2,
  },

  data() {
    return {
      // 自定义全选框
      checked: false,
      // 未全部选中的状态
      isIndeterminate: false,

      searchOption: {},

      selectedTeam: '',
      selectedTag: [],

      options1: [],

      // 标签列表
      tagList: [],

      // 分组列表
      groupList: [],

      // 选中的用户
      selection: [],

      // 黑名单弹窗
      showBlackConfirm: false,

      // 列表数据数量
      tableDataTotal: 0,
    }
  },

  computed: {
    componentId() {
      return this.pageType == 1 ? 'allTable' : 'blockTableData'
    },

    showBlackBtn() {
      return this.$store.state.roots.includes(109)
    },
  },

  methods: {
    statuslist(val) {
      this.checked = val.checked
      this.isIndeterminate = val.isIndeterminate
    },

    // 列表下边的多选
    boxChange() {
      if (this.pageType == 1) {
        const val = this.$refs.allTable.$refs.table
        if (val) {
          val.toggleAllSelection()
        } else {
          val.clearSelection()
        }
      } else {
        const val = this.$refs.blockTableData.$refs.table
        if (val) {
          val.toggleAllSelection()
        } else {
          val.clearSelection()
        }
      }
    },

    // 重置列表数据
    tableDataReset() {
      this.$refs.search.reset()
      this.selectedTeam = ''
      this.selectedTag = []
    },

    bindUserTag() {
      if (this.selection.length == 0) {
        this.$root.prompt({
          msg: '请先选择学员，方可批量操作',
          type: 'info',
        })
        this.selectedTeam = ''
      } else {
        this.$confirm(
          '每个学员只可允许存在一个分组，批量分组后，已选的学员都会分到已选的组里，会从原来所在的组移出，是否确定？',
          '提示',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.$http({
              url: '/pcUser/doGroup',
              data: {
                gid: this.selectedTeam,
                uids: this.selection,
              },
              callback: () => {
                this.tableDataReset()
                this.$root.prompt({
                  msg: '批量分组成功',
                  type: 'success',
                })
              },
            })
          })
          .catch(() => {
            this.selectedTeam = ''
          })
      }
    },

    // 拉黑
    blacklist(blackout_reason) {
      this.$http({
        url: '/User/defriend',
        data: {
          uids: this.selection,
          blackout_reason,
        },
        callback: () => {
          this.tableDataReset()
          this.$root.prompt({
            msg: '批量拉黑成功',
            type: 'success',
          })
        },
      })
    },

    // 移出黑名单
    moveOutBlackList() {
      this.$confirm('确定将当前选中的学员移出黑名单？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url: '/User/befriend',
          data: {
            uids: this.selection,
          },
          callback: () => {
            this.tableDataReset()
            this.$root.prompt({
              msg: '批量移出黑名单成功',
              type: 'success',
            })
          },
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.footer-button {
  padding: 20px 20px 30px 20px;
  .t1 {
    margin-left: 10px;
    font-size: 14px;
    line-height: 19px;
    color: rgba(51, 51, 51, 1);
  }
  .t2 {
    margin-left: 20px;
    ::v-deep .el-input {
      width: 120px;
      height: 36px;
      input {
        display: block;
        height: 100%;
      }
      .el-input__icon {
        line-height: 35px;
      }
    }
  }
  .t3 {
    margin: 0 10px;
    ::v-deep .el-button {
      width: 130px;
      height: 36px;
      line-height: 13px;
    }
  }
  .t4 {
    ::v-deep .el-button {
      width: 166px;
      height: 36px;
      line-height: 13px;
    }
  }
}
::v-deep .placeholder333 {
  input::-webkit-input-placeholder {
    color: #333;
  }
}
</style>
