<template>
  <el-dialog
    :title="title"
    width="422px"
    top="25vh"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <div>
      <div class="content-msg">
        {{ content }}
      </div>
      <div class="textarea">
        <el-input
          type="textarea"
          :placeholder="placeholder"
          v-model="value"
          :maxlength="limit"
        ></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="prompt">还可以输入{{ limit - value.length }}个字</div>
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="complete">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'messageBox',

  props: {
    title: String,
    content: String,
    placeholder: String,
    limit: {
      type: Number,
      default: 30
    }
  },

  data() {
    return {
      value: '',
      dialogVisible: false
    }
  },

  mounted() {
    this.dialogVisible = true
  },

  methods: {
    handleClose() {
      this.dialogVisible = false
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    },

    // 选择分组
    complete() {
      this.$emit('complete', this.value)
      this.handleClose()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 5px 20px 10px 20px;
  .el-dialog__header {
    padding: 16px 0 0;
    .el-dialog__title {
      font-size: 18px;
    }
  }
  textarea {
    height: 82px;
    background: #f5f5f5;
  }
}
::v-deep .el-button {
  width: 74px;
  height: 36px;
  line-height: 12px;
}
.dialog-footer {
  position: relative;
  .prompt {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    color: #999;
    top: 50%;
    transform: translateY(-50%);
  }
}
.content-msg {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(102, 102, 102, 1);
}
</style>
