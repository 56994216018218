<template>
  <div class="search-contain">
    <div v-if="showExportBtn" class="exportTable">
      <el-button
        v-if="selection.length > 0"
        @click="exportTable(1)"
        type="primary"
      >
        导出选中
      </el-button>
      <el-button @click="exportTable(2)" type="primary">导出</el-button>
    </div>
    <!--昵称 真实姓名 手机号-->
    <div class="item-contain">
      <div class="search-item search-item1">
        <div class="vm search-text">关键词</div>
        <div class="vm select">
          <el-select v-model="search.searchType">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="vm input">
          <el-input
            :placeholder="placeholder"
            v-model="search.search"
            clearable
          ></el-input>
        </div>
      </div>
    </div>
    <!--是否付费 学员分组 学员标签-->
    <div class="item-contain">
      <!--学员分组-->
      <div class="vm search-item search-item2 mr40">
        <div class="vm search-text">学员分组</div>
        <div class="vm select">
          <el-select v-model="search.gid">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="未分组" :value="-2"></el-option>
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">
                {{ item.label }}
              </span>
              <span style="float: right; color: #999999; font-size: 12px">
                {{ item.count || 0 }}人
              </span>
            </el-option>
          </el-select>
        </div>
      </div>
      <!--学员标签-->
      <div class="vm search-item search-item3 mr40">
        <div class="vm search-text">注册日期</div>
        <div class="vm select">
          <el-date-picker
            v-model="search.registerTime"
            @change="
              selectedTime(arguments[0], ['small_regdate', 'large_regdate'])
            "
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            unlink-panels
            value-format="timestamp"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
      </div>
      <!--登录时间-->
      <template v-if="pageType == 1">
        <div class="vm search-item search-item4">
          <div class="vm search-text">登录日期</div>
          <div class="vm select">
            <el-date-picker
              v-model="search.loginTime"
              @change="
                selectedTime(arguments[0], ['small_lastdate', 'large_lastdate'])
              "
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              unlink-panels
              value-format="timestamp"
              align="right"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
      </template>
      <!--拉黑时间-->
      <template v-else>
        <div class="vm search-item search-item4">
          <div class="vm search-text">拉黑日期</div>
          <div class="vm select">
            <el-date-picker
              v-model="search.blackListTime"
              @change="
                selectedTime(arguments[0], [
                  'small_operator_time',
                  'large_operator_time',
                ])
              "
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              unlink-panels
              value-format="timestamp"
              align="right"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
      </template>
    </div>
    <template v-if="pageType == 1">
      <!--登录次数-->
      <div class="item-contain">
        <!--登录次数-->
        <div class="vm search-item search-item5 mr40">
          <div class="vm search-text">登录次数</div>
          <div class="vm select">
            <div class="flex-center">
              <el-input
                :value="search.small_logincount"
                @input="search.small_logincount = toNumber(arguments[0], true)"
                clearable
              >
                <template slot="append">次</template>
              </el-input>
              <span class="middle-space">至</span>
              <el-input
                :value="search.large_logincount"
                @input="search.large_logincount = toNumber(arguments[0], true)"
                clearable
              >
                <template slot="append">次</template>
              </el-input>
            </div>
          </div>
        </div>
        <!-- 绑定手机 -->
        <div class="vm search-item search-item6 mr40">
          <div class="vm search-text">绑定手机</div>
          <div class="vm select">
            <el-select v-model="search.is_umobile">
              <el-option
                v-for="item in options6"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!--是否付费-->
        <div class="vm search-item search-item6 mr40">
          <div class="vm search-text">是否付费</div>
          <div class="vm select">
            <el-select v-model="search.is_pay">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!--用户注册来源-->
        <div class="vm search-item search-item6">
          <div class="vm search-text">注册来源</div>
          <div class="vm select">
            <el-select v-model="search.reg_source">
              <el-option
                v-for="item in options7"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!--付费金额 订单数量-->
      <div class="item-contain">
        <!--付费金额-->
        <template v-if="search.is_pay != 2">
          <div class="vm search-item search-item7 mr40">
            <div class="vm search-text">付费金额</div>
            <div class="vm select">
              <div class="flex-center">
                <el-input
                  :value="search.small_amount"
                  @input="search.small_amount = toNumber(arguments[0])"
                  clearable
                >
                  <template slot="append">元</template>
                </el-input>
                <span class="middle-space">至</span>
                <el-input
                  :value="search.large_amount"
                  @input="search.large_amount = toNumber(arguments[0])"
                  clearable
                >
                  <template slot="append">元</template>
                </el-input>
              </div>
            </div>
          </div>
        </template>
        <!--订单数量-->
        <div class="vm search-item search-item8">
          <div class="vm search-text">订单数量</div>
          <div class="vm select">
            <div class="flex-center">
              <el-input
                :value="search.small_quantity"
                @input="search.small_quantity = toNumber(arguments[0], true)"
                clearable
              >
                <template slot="append">次</template>
              </el-input>
              <span class="middle-space">至</span>
              <el-input
                :value="search.large_quantity"
                @input="search.large_quantity = toNumber(arguments[0], true)"
                clearable
              >
                <template slot="append">次</template>
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--学员标签-->
    <div class="item-contain">
      <!--是否付费-->
      <div class="search-item9">
        <div class="vm search-text">学员标签</div>
        <div class="vm select">
          <el-select
            class="placeholder333"
            multiple
            @focus="getTagList"
            v-model="search.tag_ids"
            placeholder="全部"
          >
            <el-option
              @click.native="otherTag(-1)"
              label="全部"
              :value="-1"
            ></el-option>
            <el-option
              @click.native="otherTag(-2)"
              label="无标签"
              :value="-2"
            ></el-option>
            <el-option
              @click.native="otherTag(item.value)"
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="flex-center" style="margin-top: 30px">
      <el-button @click="filterStart" type="primary" class="filter-button">
        筛选
      </el-button>
      <div @click="init" class="filter-text">清除所有筛选项</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'studentListSearch',

  props: {
    pageType: [Number, String],

    selection: Array,
    tableDataTotal: Number,
    isExportAll:Boolean,
  },

  data() {
    return {
      search: {},

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            },
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            },
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            },
          },
        ],
      },

      // 搜索-
      options1: [
        { label: '昵称', value: 1, placeholder: '输入学员昵称' },
        { label: '真实姓名', value: 2, placeholder: '输入真实姓名' },
        { label: '手机号', value: 3, placeholder: '输入手机号' },
      ],

      // 是否付费
      options2: [
        { label: '全部', value: 0 },
        { label: '是', value: 1 },
        { label: '否', value: 2 },
      ],

      // 用户注册来源
      options7: [
        { label: '全部', value: 0 },
        { label: 'h5网校', value: 1 },
        { label: '叮叮开课直播小程序', value: 2 },
        { label: '推广招生-抖音广告', value: 3 },
      ],

      options6: [
        { label: '全部', value: 0 },
        { label: '已绑定', value: 1 },
        { label: '未绑定', value: 2 },
      ],
      // 学员分组
      options3: [],

      // 学员标签
      options4: [],
    }
  },

  watch: {
    'search.is_pay'(value) {
      if (value == 2) {
        this.search = _.assign({}, this.search, {
          small_amount: '',
          large_amount: '',
        })
      }
    },
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find((item) => item.value == this.search.searchType)
        .placeholder
    },

    showExportBtn() {
      return this.$store.state.roots.includes(107)
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      //初始化搜索条件
      this.baseSeachOption()
      this.reset()
      // 第一次显示全部 不填充-1
      this.search.tag_ids = []
    },

    // 设置默认搜索条件
    baseSeachOption() {
      this.search = {
        type: this.pageType, // 1全部  2今日访问 3黑名单
        order: '', // 排序字段
        sort: 'ASC', // 排序类型  DESC 倒序   ASC 正序
        searchType: 1, // 搜索类型 1.微信名 2真实姓名 3手机号
        search: '', // 搜索内容
        gid: -1, // 分组ID
        tag_ids: [], // 标签id集合
        is_pay: 0, // 是否付费   0全部 1是 2否
        reg_source: 0, // 1 h5网校 2 叮叮开课直播小程序 3 推广招生-抖音广告
        is_umobile: 0, //绑定手机  0全部 1是 2否
        loginTime: 0,
        blackListTime: 0, // 拉黑时间
        small_amount: '', // 最小付费金额
        large_amount: '', // 最大付费金额
        registerTime: 0,
        small_regdate: 0, // 最早注册时间
        large_regdate: 0, // 最后注册时间
        small_lastdate: 0, // 最早登录时间
        large_lastdate: 0, // 最后登录时间
        small_logincount: '', // 最小登录次数
        large_logincount: '', // 最大登录次数
        small_quantity: '', // 最小购买次数
        large_quantity: '', // 最大购买次数
      }
    },

    // 设置搜索条件参数
    setSearchOption(option) {
      this.search = _.assign({}, this.search, option)
      this.filterStart()
    },

    // 导出
    exportTable(type) { 
      const verify = type == 1 ? this.selection.length : this.tableDataTotal
      const confirmStr = this.isExportAll?'确定要导出所有数据？':`确定要导出当前${verify}条数据？`
      if (verify > 0) {
        this.$confirm(confirmStr, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(
            _.assign({}, this.search, {
              uids: type == 1 ? this.selection : undefined,
            })
          )

          this.$store.dispatch(
            'open',
            `${location.origin}/User/exportExcel?query=${encodeURIComponent(
              query
            )}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    // 筛选前填充数据
    fullOption() {
      const t = this.search
      // 设置默认值
      this.search = _.assign({}, this.search, {
        tag_ids: t.tag_ids.length == 0 ? [-1] : t.tag_ids, // 最小付费金额
        small_amount: t.small_amount || t.large_amount || '', // 最小付费金额
        large_amount: t.large_amount || t.small_amount || '', // 最大付费金额
        small_regdate: t.small_regdate || t.large_regdate || 0, // 最早注册时间
        large_regdate: t.large_regdate || t.small_regdate || 0, // 最后注册时间
        small_operator_time:
          t.small_operator_time || t.large_operator_time || 0, // 最早拉黑时间
        large_operator_time:
          t.large_operator_time || t.small_operator_time || 0, // 最后拉黑时间
        small_lastdate: t.small_lastdate || t.large_lastdate || 0, // 最早登录时间
        large_lastdate: t.large_lastdate || t.small_lastdate || 0, // 最后登录时间
        small_logincount: t.small_logincount || t.large_logincount || '', // 最小登录次数
        large_logincount: t.large_logincount || t.small_logincount || '', // 最大登录次数
        small_quantity: t.small_quantity || t.large_quantity || '', // 最小购买次数
        large_quantity: t.large_quantity || t.small_quantity || '', // 最大购买次数
      })
    },

    // 筛选
    filterStart() {
      this.fullOption()
      this.$emit('search', _.assign({}, this.search))
    },

    otherTag(value) {
      if (value < 0 || this.search.tag_ids[0] < 0) {
        this.search.tag_ids = [value]
      }
    },

    // 清空筛选选项
    async reset() {
      this.filterStart()
      // 获取机构标签
      this.getTagList()
      // 获取分组列表
      this.getGroupList()
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },

    // 获取机构标签
    async getTagList() {
      const { data } = await this.$http({
        url: '/UserTag/getJigouTag',
      })
      this.options4 = data.map((item) => {
        return { label: item.tag_name, value: Number(item.tag_id) }
      })
      this.$emit('setTagList', this.options4)
    },

    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          this.options3 = data.map((item) => {
            return {
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            }
          })
          this.$emit('setGroupList', this.options3)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.search-contain {
  font-size: 0;
  position: relative;
  padding: 30px 20px;
}
.search-item {
  margin-bottom: 20px;
  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }
}
.search-text {
  min-width: 56px;
  font-size: 14px;
  line-height: 19px;
  margin-right: 10px;
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input,
::v-deep .select {
  height: 36px;
  .el-input__inner {
    height: 36px;
  }
  .el-input__icon {
    line-height: 36px;
  }
  .el-date-editor .el-range__icon {
    line-height: 31px;
  }
  .el-range-separator {
    line-height: 29px;
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}
.exportTable {
  position: absolute;
  right: 20px;
  bottom: 30px;
  z-index: 3;
}
.filter-text {
  font-size: 14px;
  line-height: 19px;
  margin-left: 20px;
  cursor: pointer;
  color: rgba(10, 162, 155, 1);
}
.search-item9 {
  ::v-deep .select {
    width: 40%;
    .el-select {
      display: block;
    }
  }
}
::v-deep .search-item1 {
  .select {
    width: 106px;
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .input {
    width: 218px;
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
::v-deep .search-item2,
::v-deep .search-item6 {
  .select {
    width: 160px;
  }
}
::v-deep .search-item3,
::v-deep .search-item4 {
  .el-date-editor {
    width: 292px;
  }
  .el-input__icon {
    line-height: 27px;
  }
}
::v-deep .search-item5,
::v-deep .search-item7,
::v-deep .search-item8 {
  .el-input {
    width: 130px;
    position: relative;
    input[type='number'] {
      padding-right: 50px;
    }
    .el-input-group__append {
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }
}
::v-deep .filter-button,
::v-deep .exportTable .el-button {
  width: 100px;
  height: 36px;
  line-height: 10px;
}
::v-deep .placeholder333 {
  input::-webkit-input-placeholder {
    color: #333;
  }
}
</style>
